import React from 'react';
import isNil from 'lodash/isNil';
import { NavLink as RouterLink, matchPath } from 'react-router-dom';
import { LinearProgress } from '@components';
import { Collapsible, CollapsibleTriggerChildProps } from '@components/collapsible';
import { MuiStack } from '@components/stack';
import Typography from '@components/typography';
import { commonCn } from '@utils/cn';

export type NavBarItemProps = Omit<React.ComponentProps<'li'>, 'children'> & {
  depth: number;
  icon?: React.ElementType;
  initialOpen?: boolean;
  isActiveProp?: boolean;
  title: string;
  progress?: number;
} & (
    | {
        href: string;
        children?: never;
      }
    | {
        href?: never;
        children?: React.ReactComponentElement<any>[];
      }
  );

const NavBarItemContent = ({
  icon: Icon,
  collapsibleTriggerProps,
  isChildItem,
  title,
  className,
}: {
  icon?: React.ElementType;
  isChildItem: boolean;
  title: string;
  collapsibleTriggerProps?: CollapsibleTriggerChildProps;
  className?: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const CollapsibleIcon = collapsibleTriggerProps?.icon;

  return (
    <div className={'ps-space-between ps-flex ps-flex-row ps-items-center ps-justify-between'}>
      {Icon && <Icon className={commonCn('ps-mr-[10px] ps-text-inherit ps-shrink-0', className)} size={22} />}
      <Typography className={commonCn(`ps-mt-0.5 ps-font-semibold ps-w-full`, className)}>{title}</Typography>

      {/* spacer */}
      <div style={{ flex: 1 }} />

      {CollapsibleIcon && <CollapsibleIcon className={commonCn('ps-text-gray-800 ps-shrink-0', className)} size={16} />}
    </div>
  );
};

const isMatched = (href: string | undefined) => {
  return href && matchPath(href + '/*', location.pathname);
};

export const NavBarItem: React.FC<NavBarItemProps> = ({
  depth,
  icon,
  initialOpen = false,
  title,
  progress,
  href,
  children,
  className,
  isActiveProp = false,
  ...rest
}) => {
  const isCollapsible = !!children;
  const isChildItem = depth !== 0;

  const commonClassNames =
    'ps-flex ps-bg-transparent ps-select-none ps-w-full ps-items-center ps-px-2 ps-no-underline focus:ps-bg-blue-50';

  const isActive = isActiveProp || isMatched(href);

  const isSectionActive =
    children && children.some((child) => isMatched(child?.props.href) || child?.props.isActiveProp);

  return (
    <>
      <Collapsible defaultOpen={initialOpen}>
        <Collapsible.Trigger asChild>
          {(collapsibleTriggerProps: CollapsibleTriggerChildProps) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const InnerComponent = () => {
              return (
                <li
                  className={commonCn(
                    'ps-relative ps-flex ps-h-[50px] ps-w-full ps-cursor-pointer ps-list-none ps-items-center data-[active=true]:ps-bg-gray-100 data-[active=true]:ps-text-blue',
                    collapsibleTriggerProps.open && 'ps-bg-gray-100'
                  )}
                  {...rest}
                >
                  <button
                    className={commonCn(
                      commonClassNames,
                      `ps-h-[50px] ps-w-full ps-cursor-pointer ps-rounded-md ps-border-none ps-text-left hover:ps-bg-gray-200`,
                      isChildItem && 'ps-pl-[42px] ps-pr-[12px]',
                      isActive && 'ps-bg-blue-50',
                      className
                    )}
                    {...(isCollapsible && collapsibleTriggerProps.buttonProps)}
                  >
                    <MuiStack gap={1} className={'ps-w-full ps-flex-col'}>
                      <NavBarItemContent
                        {...{ icon, isChildItem, title }}
                        {...(isCollapsible && { collapsibleTriggerProps })}
                        className={commonCn(isSectionActive ? 'ps-text-blue' : '', isActive && 'ps-text-blue')}
                      />

                      {!isNil(progress) && (
                        <div className={'ps-w-full'}>
                          <LinearProgress value={progress} />
                        </div>
                      )}
                    </MuiStack>
                  </button>
                </li>
              );
            };

            if (href) {
              return (
                <RouterLink
                  to={href}
                  className={commonCn(commonClassNames, `ps-w-full ps-p-0 focus:ps-outline-none`, className)}
                >
                  {/*<NavBarItemContent {...{ icon, isChildItem, title }} />*/}
                  <InnerComponent />
                </RouterLink>
              );
            }

            return <InnerComponent />;
          }}
        </Collapsible.Trigger>

        {isCollapsible && (
          <Collapsible.Content>
            <ul className={'ps-m-0 ps-list-none ps-bg-gray-100 ps-p-0'}>{children}</ul>
          </Collapsible.Content>
        )}
      </Collapsible>
      <div className={'ps-mb-1'} />
    </>
  );
};
