import React, { useMemo, useState } from 'react';
import { find } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { Sliders } from 'react-feather';
import {
  Button,
  ButtonProps,
  Divider,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  MuiStack,
  Typography,
} from '@components';
import { DateFilterComponent } from '@components/filter/filters/date-filter/date-filter';
import { DateFilterInterface, DateFilterValue } from '@components/filter/filters/date-filter/types';
import { MultipleSelectFilter } from '@components/filter/filters/multiple-select-filter/multiple-select-filter';
import {
  MultipleSelectFilterInterface,
  MultipleSelectFilterValue,
} from '@components/filter/filters/multiple-select-filter/types';
import { SelectFilterComponent } from '@components/filter/filters/select-filter/select-filter';
import { SelectFilter, SelectFilterValue } from '@components/filter/filters/select-filter/types';
import {
  FilterBase,
  FilterEvent,
  FilterType,
  FilterTypeEnum,
  FilterTypes,
  FilterValue,
  FiltersComponentProps,
} from '@components/filter/types';

export const DefaultLabelsFilters = {
  deleteAllButton: 'Vymazať filtre',
  filterButton: 'Filter',
  filtersOptionHeader: 'Podľa atribútu',
};

const DashedButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'outlined-dashed', color = 'light-gray', size = 'medium', ...rest }, ref) => {
    return <Button variant={variant} color={color} size={size} ref={ref} {...rest} />;
  }
);

DashedButton.displayName = 'DashedButton';

export const OutlinedFilledButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'outlined-filled', color = 'light-gray', size = 'medium', ...rest }, ref) => {
    return <Button variant={variant} color={color} size={size} ref={ref} {...rest} />;
  }
);

OutlinedFilledButton.displayName = 'OutlinedFilledButton';

const initialState = (value: FilterValue[], filters: FilterType[]) => {
  return filters.filter((filter) => value.find((selectedFilter) => filter.id === selectedFilter.id));
};

export const Filter: React.FC<FiltersComponentProps> = ({
  filters,
  onChange,
  value,
  labels = DefaultLabelsFilters,
  deleteAllButton = true,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<FilterBase<FilterTypes>[]>(initialState(value, filters));

  const emptySelectedFilters = isEmpty(selectedFilters);
  // [
  //   { id: '', value: [] },
  //   { id: 'multiplefilterid', value: { attributeName: [], value: [] } },
  // ];

  const handleFilterChange = (e: FilterEvent, singleFilterValue: FilterValue) => {
    const updatedArray = cloneDeep(value);
    const index = findIndex(updatedArray, { id: singleFilterValue.id });

    if (index !== -1) {
      updatedArray[index] = singleFilterValue;
      onChange(e, updatedArray);
    } else {
      onChange(e, [...updatedArray, singleFilterValue]);
    }
  };

  const handeDeleteFilter = (e: FilterEvent, filter: FilterBase<FilterTypes>) => {
    const newValue = value.filter((selectedFilter) => selectedFilter.id !== filter.id);
    onChange(e, newValue);
    setSelectedFilters(selectedFilters.filter((selectedFilter) => !(filter.id === selectedFilter.id)));
  };

  const availableFilters = useMemo(() => {
    return filters.filter((filter) => !selectedFilters.find((selectedFilter) => selectedFilter.id === filter.id));
  }, [filters, selectedFilters]);

  const filterDropdownContent = (
    <DropdownMenuContent className={'ps-p-2'}>
      <Typography variant={'h4'} className={'ps-mx-1 ps-text-gray-800'}>
        {labels.filtersOptionHeader}
      </Typography>
      <Divider className={'ps-mx-1'} />
      {availableFilters.map((filter, index) => {
        return (
          <DropdownMenuItem
            key={index}
            data-testid={index}
            className={'hover:ps-bg-gray-200 ps-px-0 ps-rounded ps-p-1'}
            onClick={() => {
              setSelectedFilters([...selectedFilters, filter]);
            }}
          >
            <Typography variant={'h4'} className={'ps-font-semibold'}>
              {filter.label}
            </Typography>
          </DropdownMenuItem>
        );
      })}
    </DropdownMenuContent>
  );

  return (
    <MuiStack direction={'row'} gap={2} className={'ps-items-center'}>
      {emptySelectedFilters && (
        <DropdownMenu content={filterDropdownContent}>
          <DashedButton id={'add-filter'} startIcon={<Sliders />}>
            {labels.filterButton}
          </DashedButton>
        </DropdownMenu>
      )}

      {selectedFilters.map((filter, index) => {
        if (filter.type === FilterTypeEnum.SELECT) {
          const singleFilterValue = find(value, { id: filter.id, type: FilterTypeEnum.SELECT }) as
            | SelectFilterValue
            | undefined;
          const filterProps = find(filters, { id: filter.id, type: FilterTypeEnum.SELECT })! as SelectFilter;
          return (
            <SelectFilterComponent
              key={index}
              value={singleFilterValue ?? null}
              onChange={handleFilterChange}
              onDeleteFilter={(e) => {
                handeDeleteFilter(e, filter);
              }}
              {...filterProps}
            />
          );
        }

        if (filter.type === FilterTypeEnum.MULTIPLE_SELECT) {
          const singleFilterValue = find(value, {
            id: filter.id,
            type: FilterTypeEnum.MULTIPLE_SELECT,
          }) as MultipleSelectFilterValue;
          const filterProps = find(filters, {
            id: filter.id,
            type: FilterTypeEnum.MULTIPLE_SELECT,
          })! as MultipleSelectFilterInterface;
          return (
            <MultipleSelectFilter
              key={index}
              value={singleFilterValue ?? null}
              onChange={handleFilterChange}
              onDeleteFilter={(e) => {
                handeDeleteFilter(e, filter);
              }}
              {...filterProps}
            />
          );
        }
        if (filter.type === FilterTypeEnum.DATE) {
          const singleFilterValue = find(value, {
            id: filter.id,
            type: FilterTypeEnum.DATE,
          }) as DateFilterValue;
          const filterProps = find(filters, {
            id: filter.id,
            type: FilterTypeEnum.DATE,
          })! as DateFilterInterface;

          return (
            <DateFilterComponent
              key={index}
              value={singleFilterValue ?? null}
              onChange={handleFilterChange}
              onDeleteFilter={(e) => {
                handeDeleteFilter(e, filter);
              }}
              {...filterProps}
            />
          );
        }
        return null;
      })}

      {deleteAllButton && !emptySelectedFilters && (
        <Button
          id={'delete-all'}
          variant={'text'}
          className={'ps-normal-case ps-px-0'}
          color={'gray'}
          onClick={(e) => {
            setSelectedFilters([]);
            onChange(e, []);
          }}
        >
          {labels.deleteAllButton}
        </Button>
      )}

      {!emptySelectedFilters && !isEmpty(availableFilters) && (
        <DropdownMenu content={filterDropdownContent}>
          <DashedButton id={'add-more-filters'} className={'ps-w-4.5'}>
            +
          </DashedButton>
        </DropdownMenu>
      )}
    </MuiStack>
  );
};
