import React from 'react';
import { format } from 'date-fns';
import { Trash2 } from 'react-feather';
import { FilterEvent, OutlinedFilledButton } from '@components';
import { CalendarDropdown } from '@components/filter/filters/date-filter/calendar-dropdown';
import { SelectModeComponent } from '@components/filter/filters/date-filter/select-mode-component';
import { DateFilterComponentProps, SelectDateConditionType } from '@components/filter/filters/date-filter/types';
import IconButton from '@components/icon-button';

export const PredefinedRangesConfig = {
  thisWeek: {
    getValue: () => {
      const today = new Date();
      const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      const lastDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      return { from: firstDayOfWeek, to: lastDayOfWeek };
    },
  },
  thisMonth: {
    getValue: () => {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
  lastWeek: {
    getValue: () => {
      const today = new Date();
      const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() - 7));
      const lastDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
      return { from: firstDayOfWeek, to: lastDayOfWeek };
    },
  },
  lastMonth: {
    getValue: () => {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
  q1: {
    getValue: () => {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), 0, 1);
      const lastDayOfMonth = new Date(today.getFullYear(), 2, 31);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
  q2: {
    getValue: () => {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), 3, 1);
      const lastDayOfMonth = new Date(today.getFullYear(), 5, 30);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
  q3: {
    getValue: () => {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), 6, 1);
      const lastDayOfMonth = new Date(today.getFullYear(), 8, 30);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
  q4: {
    getValue: () => {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), 9, 1);
      const lastDayOfMonth = new Date(today.getFullYear(), 11, 31);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
} as const satisfies { [key: string]: { getValue: () => { from: Date; to: Date } } };

const SelectDateConditionsDefault: SelectDateConditionType[] = [
  { type: 'from', label: 'Od', selectedLabel: 'od' },
  { type: 'to', label: 'Do', selectedLabel: 'do' },
  { type: 'range', label: 'Rozmedzie od a do', selectedLabel: 'od a do' },
  { type: 'predefinedRange', predefinedVariant: 'thisWeek', label: 'Tento týždeň', selectedLabel: 'tento týždeň' },
  { type: 'predefinedRange', predefinedVariant: 'thisMonth', label: 'Tento mesiac', selectedLabel: 'tento mesiac' },
  { type: 'predefinedRange', predefinedVariant: 'lastWeek', label: 'Minulý týždeň', selectedLabel: 'minulý týždeň' },
  { type: 'predefinedRange', predefinedVariant: 'lastMonth', label: 'Minulý mesiac', selectedLabel: 'minulý mesiac' },
  { type: 'predefinedRange', predefinedVariant: 'q1', label: '1.kvartál', selectedLabel: '1.kvartál' },
  { type: 'predefinedRange', predefinedVariant: 'q2', label: '2.kvartál', selectedLabel: '2.kvartál' },
  { type: 'predefinedRange', predefinedVariant: 'q3', label: '3.kvartál', selectedLabel: '3.kvartál' },
  { type: 'predefinedRange', predefinedVariant: 'q4', label: '4.kvartál', selectedLabel: '4.kvartál' },
];

export const DateFilterComponent: React.FC<DateFilterComponentProps> = ({
  label,
  conditions = SelectDateConditionsDefault,
  value,
  type,
  id,
  onChange,
  attributeName,
  onDeleteFilter,
}) => {
  const filterType = value?.condition ?? null;

  const valueFrom = value?.value.from ?? null;
  const valueTo = value?.value.to ?? null;

  const onModeChangeValue = (e: FilterEvent, condition: SelectDateConditionType) => {
    const mode = condition.type;

    if (mode === 'predefinedRange') {
      const { from, to } = PredefinedRangesConfig[condition.predefinedVariant].getValue();
      onChange(e, {
        id,
        type,
        attributeName,
        condition: mode,
        predefinedRange: condition.predefinedVariant,
        value: { from: format(from, 'yyyy-MM-dd'), to: format(to, 'yyyy-MM-dd') },
      });
    } else if (mode === 'from' || mode === 'to' || mode === 'range') {
      onChange(e, {
        id,
        type,
        attributeName,
        condition: mode,
        value: { from: null, to: null },
      });
    }
  };

  return (
    <div className="ps-flex ps-flex-row">
      <OutlinedFilledButton corners={'rounded-l'}>{label}</OutlinedFilledButton>
      <SelectModeComponent
        conditions={conditions}
        onChange={(e, condition) => {
          onModeChangeValue(e, condition);
        }}
        filterType={filterType}
        predefinedVariant={value?.predefinedRange}
      />

      {filterType === 'from' && (
        <CalendarDropdown
          value={valueFrom || null}
          onChangeValue={(e, value) => {
            onChange(e, {
              id,
              type,
              attributeName,
              condition: filterType,
              value: { from: value, to: null },
            });
          }}
        />
      )}

      {filterType === 'to' && (
        <CalendarDropdown
          value={value?.value.to || null}
          onChangeValue={(e, value) => {
            onChange(e, {
              id,
              type,
              attributeName,
              condition: filterType,
              value: { from: null, to: value },
            });
          }}
        />
      )}

      {filterType === 'range' && (
        <>
          <CalendarDropdown
            value={value?.value.from || null}
            onChangeValue={(e, value) => {
              onChange(e, {
                id,
                type,
                attributeName,
                condition: filterType,
                value: { from: value, to: valueTo },
              });
            }}
          />
          <CalendarDropdown
            value={value?.value.to || null}
            onChangeValue={(e, value) => {
              onChange(e, {
                id,
                type,
                attributeName,
                condition: filterType,
                value: { from: valueFrom, to: value },
              });
            }}
          />
        </>
      )}

      {/* Buttons to reset or apply filters */}
      <OutlinedFilledButton corners={'rounded-r'}>
        <IconButton onClick={onDeleteFilter}>
          <Trash2 />
        </IconButton>
      </OutlinedFilledButton>
    </div>
  );
};
