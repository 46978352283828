import { useCallback, useMemo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { NavigateOptions, useSearchParams } from 'react-router-dom';

export function useStateInSearchParams<T>(name: string, defaultValue?: T) {
  type TReturnState = T extends undefined ? T | undefined : T;

  const [searchParams, setSearchParams] = useSearchParams();
  const setSearchParamsRef = useRef(setSearchParams);
  setSearchParamsRef.current = setSearchParams;

  const defaultValueRef = useRef(defaultValue);
  defaultValueRef.current = defaultValue;

  const parseStringSafe = useCallback((stateFromParamsString: string) => {
    try {
      return stateFromParamsString ? (JSON.parse(stateFromParamsString) as T) : undefined;
    } catch (e) {
      return undefined;
    }
  }, []);

  const setState = useCallback(
    (value: React.SetStateAction<T>, options?: NavigateOptions) => {
      setSearchParamsRef.current(
        (prevSearchParams) => {
          const stateFromParamsString = prevSearchParams.get(name);
          const prevStateFromParams = stateFromParamsString ? parseStringSafe(stateFromParamsString) : undefined;
          const prevState = (prevStateFromParams ?? defaultValueRef.current) as TReturnState;

          const newValue = typeof value === 'function' ? (value as (prev: TReturnState) => T)(prevState) : value;

          // if value equals to default value, remove the search param
          if (isEqual(newValue, defaultValueRef.current)) {
            const newSearchParams = new URLSearchParams(prevSearchParams);
            newSearchParams.delete(name);
            return newSearchParams;
          }

          const newSearchParams = new URLSearchParams(prevSearchParams);
          newSearchParams.set(name, JSON.stringify(newValue));
          return newSearchParams;
        },
        { preventScrollReset: true, ...options }
      );
    },
    [name, parseStringSafe]
  );

  const stateFromParamsString = searchParams.get(name);
  const stateFromParams = useMemo(() => {
    return stateFromParamsString ? parseStringSafe(stateFromParamsString) : undefined;
  }, [parseStringSafe, stateFromParamsString]);
  const state = (stateFromParams ?? defaultValue) as TReturnState;

  return [state, setState, { searchParams, setSearchParams }] as const;
}
