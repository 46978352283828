import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { ButtonProps } from '@components';
import { OutlinedFilledButton } from '@components/filter/filter';
import { SelectFilterComponentProps } from '@components/filter/filters/select-filter/types';

type SelectedOptionsTriggerProps = Pick<
  SelectFilterComponentProps,
  'label' | 'value' | 'optionsVisibility' | 'options' | 'conditions'
> & {
  firstSelectedOption: string | undefined;
  plusItems: string | null;
  haveConditions?: boolean;
  itemDefaultLabel?: string;
  triggerButtonProps?: ButtonProps;
};

export const SelectedOptionsTrigger = React.forwardRef<
  HTMLDivElement,
  SelectedOptionsTriggerProps & { 'data-state'?: 'open' | 'closed' }
>(
  (
    {
      label,
      value,
      options,
      optionsVisibility,
      firstSelectedOption,
      plusItems,
      haveConditions,
      itemDefaultLabel,
      triggerButtonProps,
      ...rest
    },
    ref
  ) => {
    const selectedOptions = options.filter((option) => value?.value.includes(option.value));

    return (
      <>
        {((haveConditions && value?.condition) || !haveConditions) && (
          <div ref={ref} {...rest} className={'ps-whitespace-normal'}>
            <OutlinedFilledButton
              corners={!haveConditions ? (isEmpty(selectedOptions) ? 'rounded' : 'rounded-l') : 'square'}
              data-state={rest['data-state']}
              className={'ps-inline-flex ps-align-middle ps-text-center'}
              {...triggerButtonProps}
            >
              {!haveConditions ? label : (firstSelectedOption ?? itemDefaultLabel)}
              {optionsVisibility === 'short' && plusItems ? (
                <div
                  className={
                    'ps-ml-1 ps-px-0.5 ps-border ps-border-solid ps-rounded ps-bg-transparent ps-border-blue-500'
                  }
                >
                  {plusItems}
                </div>
              ) : null}
            </OutlinedFilledButton>
            {optionsVisibility === 'full' &&
              selectedOptions.map((option, index) => {
                return (
                  <OutlinedFilledButton
                    key={index}
                    className={'ps-inline-flex ps-align-middle'}
                    corners={index === selectedOptions.length - 1 ? 'rounded-r' : 'square'}
                  >
                    {option.label}
                  </OutlinedFilledButton>
                );
              })}
          </div>
        )}
      </>
    );
  }
);

SelectedOptionsTrigger.displayName = 'SelectedOptionsTrigger';
