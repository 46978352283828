import React from 'react';
import { Trash2 } from 'react-feather';
import { Button, Divider, DropdownMenu, DropdownMenuContent, MuiStack, Tooltip, Typography } from '@components';
import { OutlinedFilledButton } from '@components/filter/filter';
import { FilterSelectItem } from '@components/filter/filters/select-filter/dropdown-select-item';
import { SelectedOptionsTrigger } from '@components/filter/filters/select-filter/select-filter-option-trigger';
import { SelectFilterComponentProps, SelectFilterOption } from '@components/filter/filters/select-filter/types';
import { FilterEvent } from '@components/filter/types';

export const DefaultLabels = {
  conditionDefault: 'Vyberte podmienku',
  optionDefault: 'vyberte stav',
  optionsHeader: 'Vyberte možnosti',
  conditionsHeader: 'Vyberte podmienku',
  tooltipDelete: 'Vymazať filter',
  deleteFilters: 'Vymazať zvolené filtre',
};

export const SelectFilterComponent: React.FC<SelectFilterComponentProps> = ({
  label,
  id,
  type,
  value,
  options,
  onChange,
  conditions,
  onDeleteFilter,
  attributeName,
  deletable = true,
  renderOption,
  renderCondition,
  triggerButtonProps,
  optionsVisibility = 'short',
  labels = DefaultLabels,
}) => {
  const handleSelectFilterChange = (FilterEvent: FilterEvent, optionChecked: boolean, option: SelectFilterOption) => {
    if (value) {
      const currentSelectedOptions = value.value;
      const newSelectedOptions = optionChecked
        ? currentSelectedOptions.filter((selectedOption) => selectedOption !== option.value)
        : [...currentSelectedOptions, option.value];

      onChange(FilterEvent, { ...value, attributeName, value: newSelectedOptions });
    } else {
      onChange(FilterEvent, { id, attributeName, type, value: [option.value], condition: null });
    }
  };

  const firstSelectedOption = options.find((option) => value?.value.includes(option.value))?.label;
  const plusItems = (value?.value.length ?? 0) > 1 ? `+${value?.value.length}` : null;
  const selectedCondition = value?.condition ?? labels?.conditionDefault;
  const haveConditions = conditions && conditions.length > 0;

  const content = (
    <DropdownMenuContent className={'ps-p-2'}>
      <Typography variant={'h4'} className={'ps-mx-1 ps-text-gray-800 font-semibold'}>
        {labels?.optionsHeader}
      </Typography>
      <Divider className={'ps-mx-1'} />
      {options?.map((option, index) => {
        const checked = !!value?.value.includes(option.value);

        return (
          <FilterSelectItem
            key={index}
            index={index}
            type={'checkbox'}
            checked={checked}
            disabled={option.disabled}
            onChange={(e) => {
              handleSelectFilterChange(e, checked, option);
            }}
          >
            {renderOption ? renderOption(option, checked) : option.label}
          </FilterSelectItem>
        );
      })}
      <Button
        variant={'text'}
        size={'small'}
        className={'!ps-normal-case'}
        onClick={(e) => {
          if (value) {
            onChange(e, { ...value, value: [] });
          } else {
            onChange(e, { id, type, attributeName, value: [], condition: null });
          }
        }}
      >
        {labels.deleteFilters}
      </Button>
    </DropdownMenuContent>
  );

  return (
    <MuiStack direction={'row'}>
      {haveConditions && (
        <OutlinedFilledButton corners={!deletable ? 'rounded' : 'rounded-l'}>{label}</OutlinedFilledButton>
      )}

      {haveConditions && (
        <DropdownMenu
          id={`${id}-conditions`}
          content={
            <DropdownMenuContent className={'ps-p-2'}>
              <Typography variant={'h4'} className={'ps-mx-1 ps-text-gray-800 ps-font-semibold'}>
                {labels?.conditionsHeader}
              </Typography>
              <Divider className={'ps-mx-1'} />
              {conditions?.map((condition, index) => {
                const checked = value?.condition === condition.type;

                return (
                  <FilterSelectItem
                    key={index}
                    index={index}
                    checked={checked}
                    type={'radio'}
                    onChange={(e) => {
                      if (!value) {
                        onChange(e, { id, type, value: [], attributeName, condition: condition.type });
                      } else {
                        onChange(e, { ...value, condition: condition.type });
                      }
                    }}
                  >
                    {renderCondition ? renderCondition(condition, checked) : condition.label}
                  </FilterSelectItem>
                );
              })}
            </DropdownMenuContent>
          }
        >
          <OutlinedFilledButton corners={'square'}>{selectedCondition}</OutlinedFilledButton>
        </DropdownMenu>
      )}

      <DropdownMenu id={`${name}-filter-options`} content={content}>
        <SelectedOptionsTrigger
          label={label}
          value={value}
          options={options}
          conditions={conditions}
          itemDefaultLabel={labels?.optionDefault}
          optionsVisibility={optionsVisibility}
          firstSelectedOption={firstSelectedOption}
          plusItems={plusItems}
          haveConditions={haveConditions}
          triggerButtonProps={triggerButtonProps}
        />
      </DropdownMenu>

      {deletable && (
        <Tooltip>
          <Tooltip.Trigger asChild>
            <OutlinedFilledButton corners={'rounded-r'} onClick={onDeleteFilter} data-testId={`${id}-delete-filter`}>
              <Trash2 size={20} />
            </OutlinedFilledButton>
          </Tooltip.Trigger>
          <Tooltip.Content align={'start'}>{labels?.tooltipDelete}</Tooltip.Content>
        </Tooltip>
      )}
    </MuiStack>
  );
};
