import React from 'react';
import { DropdownMenuItem, Radio, Typography } from '@components';
import { Checkbox } from '@components/checkbox';
import { FilterEvent } from '@components/filter/types';

type FilterSelectItemProps = Omit<React.ComponentPropsWithoutRef<typeof DropdownMenuItem>, 'onChange'> & {
  checked: boolean;
  disabled?: boolean;
  onChange: (e: FilterEvent) => void;
  type?: 'radio' | 'checkbox';
  index: number;
};

export const FilterSelectItem = React.forwardRef<HTMLDivElement, FilterSelectItemProps>(
  ({ children, type, checked, onChange, index, ...props }, ref) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Component = type === 'checkbox' ? Checkbox : Radio;

    const item =
      typeof children === 'string' ? (
        <div className={'ps-flex ps-w-full ps-items-center ps-mx-1'}>
          <Component checked={checked} readOnly />
          <Typography
            className={'ps-pl-1 ps-py-1 ps-w-full ps-cursor-pointer font-semibold'}
            as={'label'}
            variant={'h4'}
          >
            {children}
          </Typography>
        </div>
      ) : (
        children
      );

    return (
      <DropdownMenuItem
        className={'ps-px-0 hover:ps-bg-gray-200 ps-rounded'}
        data-testid={index}
        onSelect={(e) => {
          // Prevent closing menu
          e.preventDefault();
          onChange(e);
        }}
        ref={ref}
        {...props}
      >
        {item}
      </DropdownMenuItem>
    );
  }
);

FilterSelectItem.displayName = 'FilterSelectItem';
