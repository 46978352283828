import React, { useCallback, useMemo } from 'react';
import {
  Button,
  DefaultLabels,
  Divider,
  DropdownMenu,
  DropdownMenuContent,
  FilterEvent,
  OutlinedFilledButton,
  Typography,
} from '@components';
import {
  MultipleSelectFilterComponentProps,
  OptionValue,
} from '@components/filter/filters/multiple-select-filter/types';
import { FilterSelectItem } from '@components/filter/filters/select-filter/dropdown-select-item';

export const MultipleSelectFilter: React.FC<MultipleSelectFilterComponentProps> = ({
  parameters,
  onChange,
  value,
  id,
  type,
  label,
  labels = DefaultLabels,
  onDeleteFilter,
}) => {
  const handleChange = useCallback(
    ({
      e,
      attributeName,
      optionValue,
      multiple,
      checked,
    }: {
      e: FilterEvent;
      attributeName: string;
      optionValue: OptionValue;
      multiple: boolean;
      checked: boolean;
    }) => {
      const newValue = { ...value?.value };
      if (!newValue[attributeName]) {
        newValue[attributeName] = [optionValue];
      } else {
        const currentSelectedOptions = newValue[attributeName];
        const multipleNewSelectedOptions = checked
          ? currentSelectedOptions.filter((selectedOption) => selectedOption !== optionValue)
          : [...currentSelectedOptions, optionValue];
        newValue[attributeName] = multiple ? multipleNewSelectedOptions : [optionValue];
      }

      onChange(e, { id, type, value: newValue });
    },
    [id, onChange, type, value]
  );

  const clearFilters = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onDeleteFilter(e);
  };

  const renderedParameters = useMemo(
    () =>
      parameters.map(({ label, attributeName, options, multiple, renderOption }) => (
        <React.Fragment key={attributeName}>
          <Typography variant="h4" className="ps-mx-1 ps-mt-1 ps-text-gray-800 ps-font-semibold">
            {label}
          </Typography>
          <Divider className="ps-mx-1" />

          {options?.map((option, index) => {
            const checked = value?.value?.[attributeName]?.includes(option.value) ?? false;

            return (
              <FilterSelectItem
                key={index}
                index={index}
                checked={checked}
                type={multiple ? 'checkbox' : 'radio'}
                onChange={(e) =>
                  handleChange({ e, attributeName, optionValue: option.value, multiple: !!multiple, checked })
                }
              >
                {renderOption ? renderOption(option) : option.label}
              </FilterSelectItem>
            );
          })}
        </React.Fragment>
      )),
    [parameters, handleChange, value]
  );

  return (
    <DropdownMenu
      id={`${id}-filter-options`}
      content={
        <DropdownMenuContent className="ps-p-2">
          {renderedParameters}
          <Button variant="text" size="small" className="!ps-normal-case" onClick={clearFilters}>
            {labels.deleteFilters}
          </Button>
        </DropdownMenuContent>
      }
    >
      <OutlinedFilledButton corners="rounded">{label}</OutlinedFilledButton>
    </DropdownMenu>
  );
};

export default MultipleSelectFilter;
