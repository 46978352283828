import { DefaultLabelsFilters } from '@components';
import { DateFilterInterface, DateFilterValue } from '@components/filter/filters/date-filter/types';
import {
  MultipleSelectFilterInterface,
  MultipleSelectFilterValue,
} from '@components/filter/filters/multiple-select-filter/types';
import { SelectFilter, SelectFilterValue } from './filters/select-filter/types';

export const FilterTypeEnum = {
  SELECT: 'select',
  NUMBER: 'number',
  MULTIPLE_SELECT: 'multiple-select',
  DATE: 'date',
} as const;

export type FilterTypes = (typeof FilterTypeEnum)[keyof typeof FilterTypeEnum];

export type FilterEvent = Event | React.MouseEvent<HTMLButtonElement>;

export interface FiltersComponentProps {
  filters: FilterType[];
  value: FilterValue[];
  onChange: (e: FilterEvent, value: FilterValue[]) => void;
  deleteAllButton?: boolean;
  labels?: typeof DefaultLabelsFilters;
}

export type FilterType = SelectFilter | MultipleSelectFilterInterface | DateFilterInterface;
export type FilterValue = SelectFilterValue | MultipleSelectFilterValue | DateFilterValue;

export type Condition<T> = {
  type: T;
  selectedLabel?: string;
  label: string;
};

export interface FilterBase<T> {
  id: string;
  type: T;
}
