import React, { useContext } from 'react';

const POPUP_Z_INDEX_START = 1000;
const POPUP_Z_INDEX_STEP = 100;

export type WithPopupContextProps = {
  zIndex: number;
};

export const getPopupZIndex = (popupLevel: number) => {
  return POPUP_Z_INDEX_START + popupLevel * POPUP_Z_INDEX_STEP;
};

export const withPopupContext = <
  TBaseComponent extends React.ComponentType<TProps & WithPopupContextProps>,
  TProps = TBaseComponent extends React.ComponentType<infer T & WithPopupContextProps> ? T : never,
>(
  BaseComponent: TBaseComponent
) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Component = React.forwardRef<TBaseComponent, TProps>((props, ref) => {
    const popupContext = usePopupContext();
    const { popupLevel } = popupContext;

    const newPopupLevel = popupLevel + 1;
    const zIndex = getPopupZIndex(newPopupLevel);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const TypedBaseComponent = BaseComponent as React.FC<TProps & WithPopupContextProps>;

    return (
      <PopupContext.Provider value={{ popupLevel: newPopupLevel }}>
        <TypedBaseComponent {...(props as TProps)} zIndex={zIndex} ref={ref} />
      </PopupContext.Provider>
    );
  });

  Component.displayName = `withPopupContext(${BaseComponent.displayName ?? BaseComponent.name})`;
  return Component;
};

export const PopupContext = React.createContext({ popupLevel: 0 });

export const usePopupContext = () => {
  const context = useContext(PopupContext);
  return context ?? { popupLevel: 0 };
};
