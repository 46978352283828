import React from 'react';
import {
  DefaultLabels,
  Divider,
  DropdownMenu,
  DropdownMenuContent,
  FilterEvent,
  OutlinedFilledButton,
  Typography,
} from '@components';
import {
  PredefinedRanges,
  SelectDateConditionType,
  SelectDateConditionVariants,
} from '@components/filter/filters/date-filter/types';
import { FilterSelectItem } from '@components/filter/filters/select-filter/dropdown-select-item';

export const SelectModeComponent = ({
  onChange,
  filterType,
  predefinedVariant,
  conditions,
}: {
  onChange: (e: FilterEvent, condition: SelectDateConditionType) => void;
  filterType: SelectDateConditionVariants | 'predefinedRange' | null;
  predefinedVariant?: PredefinedRanges | null;
  conditions: SelectDateConditionType[];
}) => {
  return (
    <DropdownMenu
      id={`date-conditions`}
      content={
        <DropdownMenuContent className={'ps-p-2'}>
          <Typography variant={'h4'} className={'ps-mx-1 ps-text-gray-800 ps-font-semibold'}>
            {DefaultLabels.conditionsHeader}
          </Typography>
          <Divider className={'ps-mx-1'} />
          {conditions.map((condition, index) => {
            return (
              <FilterSelectItem
                key={index}
                index={index}
                checked={
                  condition.type === 'predefinedRange'
                    ? predefinedVariant === condition.predefinedVariant
                    : filterType === condition.type
                }
                type={'radio'}
                onChange={(e) => {
                  onChange(e, condition);
                }}
              >
                {condition.label}
              </FilterSelectItem>
            );
          })}
        </DropdownMenuContent>
      }
    >
      <OutlinedFilledButton corners={'square'}>
        {conditions.find((cond) =>
          cond.type === 'predefinedRange' ? predefinedVariant === cond.predefinedVariant : filterType === cond.type
        )?.selectedLabel || 'vyberte možnosť'}
      </OutlinedFilledButton>
    </DropdownMenu>
  );
};
